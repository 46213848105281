.journey{
    background-color: var(--journeyColor);
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: space-between;
}

.j-left{
    padding: 15rem 17rem;
}

.j-divider1{
    border: 1px solid var(--primaryColor);
    width: 35%;
    margin: 3rem 0;
}


.jleft-content h2{
    font-family: 'Poppins';
}

.jleft-content>:nth-child(2){
    color: var(--primaryColor);
    font-style: italic;
}

/* right content */

.j-right{
    width: 50%;
    margin-top: 17rem;
}

.j-divider2{
    border: 1px solid var(--primaryColor);
    margin: 3rem 0;
    width: 70rem;
    position: absolute;
    right: 0;
}

.j-video img{
    position: relative;
    max-width: 50rem;
    margin-left: -10.55rem;
}

.j-video>:nth-child(2){
    width: 7rem;
    margin-bottom: 10rem;
    margin-left: -28rem;
}

@media screen and (max-width: 1440px) {

    .journey{
        height: 80vh;
    }

    .j-left{
       padding: 7rem 10rem;
    }

    .jleft-content{
        margin-top: -1rem;
    }

    .jleft-content h2{
        font-size: 3rem;
        margin-top: -.5rem;
    }

    .j-video img{
        max-width: 30rem;
        margin-left: -3rem;
    }

    .j-video{
        margin-top: -7rem;
    }

    .j-divider2{
        max-width: 48rem;
    }

    .j-video>:nth-child(2){
        max-width: 7rem;
        margin-bottom: 5rem;
        margin-left: -19rem;
    }

  }

  /* tablet */

  @media screen and (max-width: 856px) {

    .journey{
        height: 55vh;
    }

    .j-left{
       padding: 5rem 6rem;
    }

    .jleft-content h2{
        font-size: 2rem;
    }

    .j-divider1{
        margin: 1.5rem 0;
    }

    .j-video{
        margin-top: -11rem;
    }

    .j-video img{
        max-width: 20rem;
        margin-left: -3rem;
    }

    .j-video>:nth-child(2){
        max-width: 4rem;
        margin-bottom: 3.5rem;
        margin-left: -12rem;
    }

    .j-divider2{
        max-width: 29.7rem;
    }
  }

  @media screen and (max-width: 940px) {

    .journey{
        height: 55vh;
    }

    .j-left{
       padding: 5rem 5rem;
    }

    .jleft-content h2{
        font-size: 2rem;
    }

    .j-divider1{
        margin: 1.5rem 0;
    }

    .j-video{
        margin-top: -11rem;
    }

    .j-video img{
        max-width: 20rem;
        margin-left: -3rem;
    }

    .j-video>:nth-child(2){
        max-width: 4rem;
        margin-bottom: 3.5rem;
        margin-left: -12rem;
    }

    .j-divider2{
        max-width: 32.4rem;
    }
  }