.career{
    width: 100%;
    height: 160vh;
    background-color: var(--journeyColor);
}

.career-content{
    padding: 15rem 17rem;
    justify-content: space-between;
    display: flex;
}

.career-left{
    width: 100%;
}

.career-career{
    display: flex;
    align-items: center;
    gap: 1.1rem;
}

.career-divider{
    border: 1px solid var(--primaryColor);
    width: 1px;
    height: 2.5rem;
}

.career-career h2{
    font-family: 'Poppins';
    color: var(--textColor);
}

.career-first h4{
    color: var(--textColor);
    margin-top: 5rem;
    font-size: 1.4rem;
    font-family: 'Poppins';
    
}

.career-first h5{
    margin-top: 1rem;
    font-weight: 400;
    font-size: 1rem;
    font-weight: 300;
}

/* second career */

.career-second{
    margin-top: 30rem;
}

.career-second h5{
    font-size: 1rem;
    font-weight: 400;
    font-weight: 300;
}

.career-second h2{
    color: var(--textColor);
}

.career-second span{
    color: var(--primaryColor);
    font-family: 'Poppins';
    font-style: italic;
}

/* right side */

.career-right-img img{
    max-width: 20rem;
    max-height: 25rem;
}

.career-right-img{
    display: grid;
    grid-gap: 100px;
    grid-template-columns: repeat(2,1fr);
}

.img1{
    grid-column: span 2;
}

.img1 img{
    margin-left: 7.2rem;
    margin-top: 1.2rem;
}

.img2{
    margin-top: -14rem;
    margin-left: -30rem;
}

.img3{
    margin-left: -3.1rem;
}

.career-right-content{
    margin-top: 6rem;
    position: absolute;
    left: 45rem;
}

.career-right-content h5{
    font-weight: 500;
    line-height: 1.3rem;
    font-size: 1.1rem;
    font-weight: 300;
}

.career-right-content>:nth-child(2){
    margin-top: 2rem;
}

/* media queries */

@media screen and (max-width: 1440px) {

    .career{
        height: 165vh;
    }

    .career-content{
       padding: 7rem 10rem;
    }

    .img1{
        margin-left: -2rem;
    }

    .career-first h5{
        font-weight: 300;
        max-width: 90%;
    }

    .career-second h5{
        font-weight: 300;
    }

    .career-second h2{
        z-index: 1;
        position: absolute;
        letter-spacing: 1px;
    }

    .img2 img{
        margin-left: 6rem;
        margin-top: -2.5rem;
        z-index: -1;
    }

    .career-second{
        margin-top: 18rem;
    }

    .career-second h2{
        font-size: 3.4rem;
        line-height: 3.5rem;
    }

    .career-right-content{
        margin-top: 2rem;
        max-width: 100%;
        position: absolute;
        margin-left: -17rem;
    }

    .career-right-content{
        max-width: 100%;
    }

    .career-right-content h5{
        font-weight: 300;
        margin-left: -1.5rem;
    }
}

/* tablet */

@media screen and (max-width: 856px){

    .career{
        height: 125vh;
    }
    .career-content{
        padding: 5rem 3.5rem;
    }

    .career-first h4{
        font-size: 1rem;
    }

    .career-first h5{
        font-size: .8rem;
        word-break: break-all;
        max-width: 50%;
    }

    .img1 img{
        max-width: 10rem;
    }

    .img2 img{
        max-width: 10rem;
        margin-top: 8rem;
        margin-left: 20rem;
    }

    .img3 img{
        position: absolute;
        max-width: 10rem;
        margin-left: -2.5rem;
    }

    .career-right{
        margin-right: 5rem;
        position: absolute;
        right: 0;
    }

    .career-second{
        margin-top: 12.5rem;
    }

    .career-second h5{
        font-size: .8rem;
    }

    .career-second h2{
        font-size: 2rem;
    }

    .career-right-content{
        position: absolute;
        margin-top: 10rem;
        margin-left: -60rem;
        max-width: 100%;
        word-break: break-all;
    }
}