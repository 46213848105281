.navbar{
    max-width: 100%;
    background: transparent;
}

.nav-links{
    display: flex;
    position: absolute;
    justify-content: flex-end;
    padding: 2rem 3rem;
    width: 100%;
    list-style-type: none;
    align-items: center;
    font-weight: 400;
    height: 10vh;
}

.nav-links li{
    padding: 0 20px;
    margin: 0 10px;
    color: var(--whiteColor);
    text-decoration: none;
}

.nav-links li:hover{
    color: var(--primaryColor);
}

.nav-links li::before{
    content: '';
    width: 0;
    height: 2px;
    background-color: var(--primaryColor);
    margin: auto;
    display: block;
}

.nav-links li:hover::before{
    width: 70%;
    transition: width 0.3s linear;
    margin-top: 5px;
}

.nav-links a{
    cursor: pointer;
}

.hamburger{
    position: absolute;
    display: none;
}

@media screen and (max-width: 1440px) {
    .nav-links{
        padding: 2rem 1rem;
    }

    .nav-links li{
        padding: 10px;
        font-size: .8rem;
    }
}

@media screen and (max-width: 940px) {
    .navbar{
        max-width: 100%;
        background-color: rgba(0, 0, 0, .9);
    }

    .hamburger{
        margin: 2rem 2rem;
        display: block;
        right: 0;
        z-index: 10000;
    }

    .nav-links{
        position: fixed;
        right: -100%;
        flex-direction: column;
        background-color: rgba(0, 0, 0, .9);
        height: 100vh;
        width: 100%;
        z-index: 999;
        justify-content: center;
        transition: .3s;
    }

    .nav-links.active {
        right: 0;
    }

    .nav-items{
        margin-top: 2rem;
    }
}

