.heading4{
    width: 100%;
    height: 110vh;
}

.heading4-content{
    margin: 15rem 25rem;
    max-width: 100%;
}

.heading4-text{
    display: flex;
    align-items: center;
    gap: 1.1rem;
}

.heading4-divider{
    border: 1px solid var(--primaryColor);
    height: 2.5rem;
}

.heading4-text h2{
    font-family: 'Poppins';
    color: var(--textColor);
}

.heading4-p{
    margin: 5rem 0;
}

.heading4-p p{
    font-size: 1.3rem;
}

.heading4-dot{
    display: flex;
    margin: 2rem 0;
    align-items: center;
    gap: 1.1rem;
}

.dotdot{
    width: 7px;
    height: 7px;
    background-color: var(--primaryColor);
    border-radius: 50%;
}

.heading4-dot h4{
    font-weight: 400;
}

/* media query */

@media screen and (max-width: 1440px) {
    .heading4-content{
        margin: 15rem 20rem;
    }

    .heading4-p p{
        font-size: 1.1rem;
        font-weight: 300;
    }

    .heading4{
        height: 125vh;
    }

    .heading4-dot h4{
        font-weight: 300;
    }
}