.education{
    width: 100%;
    height: 120vh;
    position: relative;
}

.edu-content{
    padding: 10rem 17rem;
    display: flex;
    align-items: center;
    gap: 1.1rem;
}

.edu-divider1{
    border: 1px solid var(--primaryColor);
    width: 1px;
    height: 2.5rem;
}

.edu-text{
    font-family: 'Poppins';
}

.edu-below{
    display: grid;
    grid-template-columns: 2fr 2fr;
    gap: 9rem;

    align-items: center;
    justify-items: center;
}

.edu-uni-content{
    margin-top: 3rem;
    word-break: break-all;
}

.edu-uni-content h5{
    width: 100%;
}

.edu-grid3 h5{
    width: 66%;
    word-break: break-all;
}

.two-five{
    font-family: 'Poppins';
    font-style: oblique;
    font-variant: small-caps;
}


.five-box{
    width: 23px;
    height: 23px;
    background-color: var(--primaryColor);
    margin-top: -1.8rem;
    margin-left: -.3rem;
}

.two-six>:nth-child(2){
    width: 23px;
    height: 23px;
    background-color: var(--primaryColor);
    margin-top: -1.8rem;
    margin-left: -.3rem;
}

.two-ten>:nth-child(2){
    width: 23px;
    height: 23px;
    background-color: var(--primaryColor);
    margin-top: -1.8rem;
    margin-left: -.3rem;
}

.two-nine>:nth-child(2){
    width: 23px;
    height: 23px;
    background-color: var(--primaryColor);
    margin-top: -1.8rem;
    margin-left: -.3rem;
}

.edu-uni-content h3{
    color: var(--primaryColor);
}

.two-six{
    font-family: 'Poppins';
    font-style: oblique;
    font-variant: small-caps;
}

.two-nine{
    font-family: 'Poppins';
    font-style: oblique;
    font-variant: small-caps;
}

.two-ten{
    font-family: 'Poppins';
    font-style: oblique;
    font-variant: small-caps;
}

.edu-uni-content h5{
    font-weight: 400;
    font-size: 1rem;
}

.edu-grid1{
    margin-left: -31.4rem;
}

.plus{
    position: absolute;
}

.plus img{
    display: block;
    margin: auto;
}

.plus>:nth-child(1){
    margin-left: 42rem;
    max-height: 50rem;
    margin-top: 30rem;
}

.plus>:nth-child(2){
    max-width: 90%;
    margin-left: -4rem;
    margin-top: -25rem;
}

/* media queries */

@media screen and (max-width: 1440px) {

    .edu-content{
       padding: 7rem 10rem;
    }

    .edu-below{
        margin-top: -2rem;
    }

    .edu-grid1{
        margin-left: -18.5rem;
    }

    .plus{
        margin-top: 1rem;
    }

    .plus>:nth-child(1){
        margin-left: 35rem;
        max-height: 45rem;
        margin-top: 20rem;
    }
    
    .plus>:nth-child(2){
        max-width: 90%;
        margin-left: -1rem;
        margin-top: -22rem;
    }

    .edu-uni-content h5{
        font-weight: 300;
    }

    .edu-grid3{
        margin-top: 1.5rem;
    }

}

/* tablet */

@media screen and (max-width: 856px) {

    .education{
        height: 90vh;
    }
    .edu-content{
        padding: 5rem 3.5rem;
    }

    .edu-below h2{
        font-size: 1.5rem;
        text-align: justify;
    }

    .edu-uni-content h3{
        font-size: 1rem;
    }

    .edu-uni-content h5{
        font-size: .8rem;
    }
}

@media screen and (max-width: 940px) {

    .education{
        height: 90vh;
    }
    
    .edu-content{
        padding: 5rem 5rem;
    }

    .edu-below h2{
        font-size: 1.5rem;
        text-align: justify;
    }

    .edu-uni-content h3{
        font-size: 1rem;
    }

    .edu-uni-content h5{
        font-size: .8rem;
    }

    .edu-grid1{
        margin-left: -6rem;
    }

    .plus img{
        display: block;
        margin: auto;
    }
    
    .plus>:nth-child(1){
        margin-left: 25rem;
        max-height: 35rem;
        margin-top: 17rem;
    }
    
    .plus>:nth-child(2){
        max-width: 95%;
        margin-top: -17rem;
    }
}