.mainblog{
    background-color: var(--journeyColor);
    width: 100%;
    height: 320vh;
    z-index: -1;
    position: absolute;
}

.mainblog-content{
    margin: 10rem 17rem;
}

.mainblog-first{
    justify-content: space-between;
    display: flex;
}

.mainblog-heading{
    margin-top: 8rem;
    display: flex;
    align-items: center;
    gap: 1.1rem;
    max-width: 100%;
}

.mainblog-divider{
    border: 1px solid var(--primaryColor);
    height: 2.8rem;
    margin-top: .5rem;
}

.mainblog-paragraph{
    margin-top: 3rem;
    max-width: 62.3%;
    word-break: break-all;
}

.mainblog-paragraph{
    line-height: 1.7rem;
}


/* right side */

.mainblog-right{
    max-width: 100%;
}

.mainblog-bg{
    width: 45rem;
    height: 25rem;
    background-color: var(--syndrome);
    position: absolute;
    z-index: -1;
    top: 0;
    right: 0;
}

.mainblog-pic img{
    max-width: 45rem;
    object-fit: cover;
}

/* below */

/* first row */

.mt-second{
    width: 100%;
    display: flex;
    margin: 1rem 0;
    width: 100%;
    gap: 2rem;
    justify-content: space-evenly;
    margin-top: -5rem;
}

.mainblog-foot{
    width: 100%;
    position: absolute;
}

@media screen and (max-width: 1440px) {

    .mainblog{
        height: 360vh;
    }

    .mainblog-content{
        margin: 7rem 10rem;
    }

    .mainblog-pic img{
        max-width: 35rem;
        object-fit: cover;
    }

    .mainblog-bg{
        width: 35rem;
        height: 20rem;
    }

    
    .mainblog-paragraph{
    margin-top: 3rem;
    max-width: 78.5%;
    }

    .mainblog-foot{
        width: 100%;
        position: absolute;
        margin-top: 2rem;
    }
}
