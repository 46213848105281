.footer{
    width: 100%;
    height: 40vh;
}

.footer-content{
    padding: 0 17rem;
}

.f-divider1{
    border: 1px solid var(--textColor);
}

.footer-context{
    display: flex;
    justify-content: space-between;
    margin: 3rem 0;
}

.footer-info{
    flex-direction: column;
    display: flex;
}

.context1{
    display: flex;
    gap: 2rem;
    line-height: 3rem;
}

.context1 li{
    color: var(--textColor);
}

.context1 li:hover{
    color: var(--primaryColor);
}

.help-divider2{
    border: 1px solid var(--textColor);
}

.footer-icons{
    display: flex;
    gap: 2rem;
    margin-top: 1.3rem;
}

.footer-icons a{
    color: var(--textColor);
}

.footer-icons a:hover{
    color: var(--primaryColor);
}

.footer-info a{
    padding: 1rem 0;
    color: var(--textColor);
}

.footer-info a:hover{
    color: var(--primaryColor);
}

.f-bottomline{
    padding: 2rem 0;
    font-size: .7rem;
    font-weight: 600;
}

.context1 a{
    cursor: pointer;
}

/* media queries */

@media screen and (max-width: 1440px) {
    .footer-content{
        padding: 0 10rem;
    }
}

@media screen and (max-width: 856px) {
    .footer-content{
        padding: 0 2.8rem;
    }
}