.heading2{
    width: 100%;
    height: 65vh;
    background-color: var(--syndrome);
}

.heading2-content{
    display: flex;
    justify-content: space-evenly;
    overflow: hidden;
}

.heading2-left{
    margin-top: 3rem;
}

.heading2-left img{
    max-width: 30rem;
    height: 40rem;
}

/* right */

.heading2-right{
    margin: 9% 0;
}

.heading2-heading{
    display: flex;
    align-items: center;
    gap: 1.1rem;
}

.heading2-divider{
    border: 1px solid var(--primaryColor);
    height: 2.5rem;
}

.heading2-heading h2{
    font-family: 'Poppins';
    color: var(--textColor);
}

.heading2-p{
    margin-top: 3rem;
}

.heading2-p p{
    font-size: 1.1rem;
}

.heading2-p>:nth-child(2){
    margin-top: 2rem;
}

@media screen and (max-width: 1440px) {

    .heading2-left img{
        max-width: 30rem;
        height: 31rem;
    }

    .heading2-p{
        font-weight: 300;
    }
}