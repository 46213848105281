.help{
    width: 100%;
    height: 40vh;
}

.help-content{
    display: block;
    margin: 13rem auto;
    justify-content: center;
    display: flex;
    justify-content: space-around;
    width: 60%;
    border: 1px solid var(--primaryColor);
    padding: 3rem 1rem;
}

.help-text h2{
    color: var(--textColor);
    font-family: 'Poppins';
    width: 100%;
    margin-left: 5rem;
}

.help-text span{
    color: var(--primaryColor);
    font-style: italic;
}

.h-divider{
    border: 1px solid var(--primaryColor);
    height: 7rem;
    position: absolute;
    margin-left: 15rem;
    margin-top: -1.5rem;
}

.help-btn{
    display: block;
    margin: auto;
    margin-left: 14rem;
}
.help-btn button{
    width: 15rem;
    background-color: var(--textColor);
    color: var(--whiteColor);
    border: none;
}

@media screen and (max-width: 1440px) {
    .help-content{
        margin: 7rem auto;
        width: 77%;
    }

    .help-text h2{
        font-size: 3rem;
    }

    .h-divider{
        margin-left: 9rem;
    }
}

@media screen and (max-width: 856px) {

    .help-content{
        margin: 7rem auto;
        width: 90%;
    }

    .help-text h2{
        font-size: 1.5rem;
    }

    .h-divider{
        margin-left: 5rem;
        height: 4rem;
        margin-top: -.7rem;
    }

    .help-btn{
        margin-left: 14rem;
    }

    .help-btn button{
        width: 10rem;
    }
}