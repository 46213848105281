.health{
    width: 100%;
    height: 115.4vh;
    background-color: var(--healthColor);
    position: relative;
}

.health-content{
    display: flex;
    justify-content: space-between;
}

.health-left{
    max-width: 100%;
    position: absolute;
}

.health-left img{
    max-width: 50%;
    object-fit: cover;
}

/* right side */

.health-right{
    position: absolute;
    right: 18rem;
    top: 15rem;
}

.hr-content span{
    color: var(--primaryColor);
}

.health-right h2{
    color: var(--textColor);
    margin: 3rem 0;
}

.health-right h5{
    font-size: 1.1rem;
    font-weight: 300;
}

.health-below{
    margin-top: 10rem;
}

.health-below h5{
    font-size: 1.1rem;
    font-weight: 400;
    font-weight: 300;
}

.health-below>:nth-child(2){
    margin-top: 2rem;
}

@media screen and (max-width: 1440px) {
    .health{
        height: 104.6vh;
    }

    .health-right{
        right: 8rem;
        top: 10rem;
    }

    .hr-content h2{
        font-size: 3rem;
        line-height: 3rem;
    }

    .health-right h5{
        font-weight: 300;
    }

    .health-below{
        margin-top: 5rem;
    }

    .health-below h5{
        font-weight: 300;
    }
}

/* tablet */

@media screen and (max-width: 856px) {
    .health{
        height: 62.2vh;
    }

    .health-right{
        right: 3rem;
        top: 3rem;
    }

    .health-right h5{
        font-size: 1rem;
    }

    .hr-content h2{
        font-size: 2rem;
    }

    .health-below{
        margin-top: 3rem;
    }

    .health-below h5{
        font-size: .8rem;
    }
}